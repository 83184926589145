import BaseService from "@core/services/baseService";

export default class StaffService extends BaseService{
    createStaff(...args){
        return this.axiosIns.post(this.formConfig.staffEndpoint, ...args)
    }

    updateStaff(...args){
        return this.axiosIns.patch(this.formConfig.staffEndpoint+"/"+args[0].id, ...args)
    }

    getStaff(...args){
        return this.axiosIns.get(this.formConfig.staffEndpoint+"/"+args[0].id, ...args)
    }

    deleteStaff(...args){
        return this.axiosIns.delete(this.formConfig.staffEndpoint+"/"+args[0].id)
    }

    getByPractice(...args){
        return this.axiosIns.get(this.formConfig.staffEndpoint, {params: {'practice_id': args[0].practice_id}})
    }
}