<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="staff-create-wrapper"
  >

    <b-row>
      <b-col
          cols="4" class="mt-50">
        <h2>Risk: {{ staffRisk }}
        </h2>
      </b-col>
      <b-col
          cols="4" class="mt-50 text-center">
        <h2 v-if="staff.isComplete"><feather-icon icon="CheckIcon" size="28" class="text-success" /> Complete</h2>
        <h2 v-if="!staff.isComplete"><feather-icon icon="AlertTriangleIcon" size="28" class="text-warning" /> Incomplete</h2>
      </b-col>
      <b-col
          cols="4"
          class="mt-50 text-right"
      >
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :href="'/staff/'+staff.id+'/edit'"
        >
          Edit
        </b-button>
      </b-col>
    </b-row>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <strong>Name:</strong> {{ staff.firstName }} {{ staff.lastName }}
        </b-col>
        <b-col md="6">
          <strong>Staff Member Role Attributes:</strong>
          <ul>
            <li v-for="role in staff.roles">{{ role }}</li>
          </ul>
        </b-col>
        <b-col md="12" class="mt-3">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Question</th>
              <th class="text-center">Answer</th>
              <th class="text-center">File</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(riskQuestion,index) in riskQuestions" :key="index">
              <td>{{ riskQuestion.question }}</td>
              <td class="text-center">
                <div v-if="form[riskQuestion.questionLabel]">
                  {{ form[riskQuestion.questionLabel].answer }}
                </div>
              </td>
              <td class="text-center">
                <div v-if="form[riskQuestion.questionLabel]">
                  {{ form[riskQuestion.questionLabel].file }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import staffService from "@core/services/staff/useStaff";
import riskService from "@core/services/risk/useRisk";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BOverlay,
    VBTooltip,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.getRiskQuestions();
  },
  data() {
    return {
      staff: {
        id: 0,
        firstName: "",
        lastName: "",
        roles: [],
        isComplete: false,
      },
      form: {},
      riskQuestionCategories: [],
      riskQuestions: [],
      staffRisk: 0,
      pageLoaded: false
    }
  },
  methods: {
    getStaff() {
      if (this.$route.params.id) {
        staffService.getStaff({
          id: this.$route.params.id
        }).then(response => {
          this.staff = response.data;
          let that = this;

          if (this.staff.answers.length > 0) {
            this.staff.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }

          this.recalculateRisk();
          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': 3
      }).then(response => {
        let that = this;
        this.riskQuestions = response.data.questions;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions.forEach(function (question) {
          that.$set(that.form, question.questionLabel, {input: ""})
        });

        this.getStaff();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });

    },
    recalculateRisk() {
      this.staffRisk = riskService.recalculateRisk(this.staff.answers);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
